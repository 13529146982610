import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMethodsService } from './shared-methods.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [],
})
export class SharedModule { }
