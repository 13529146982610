import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      // Handle HTTP error
      console.error('HTTP Error: ', error.message);
    } else {
      // Handle Client Error (Angular Error, ReferenceError, etc.)
      console.error('Client Error: ', error);
    }

    // You can show user-friendly messages or log errors to a remote server here
    //alert('An unexpected error occurred.');

    // Rethrow the error if necessary
    throw error;
  }
}
