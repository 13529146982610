import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { CommonService } from "./common.service";

export const authGuard: CanActivateFn = (route, state) => {
  if (inject(CommonService).isLogin()) {
    return true;
  } else {
    inject(Router).navigate([`/auth`], {
      queryParams: {
        cb: btoa(state.url),
      },
    });
  }
  return false;
};
