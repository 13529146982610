export const environment = {
  production: true,
  api: {
    endpoint: "https://mc.osinetwork.net/api/v1",
    identifier: "KL&S89L_HG",
    rooturl: "https://mc.osinetwork.net",
    payurl: "https://pay.sandbox.payrillagateway.com/myc",
  },
  session_keys: {
    login: "__CG_DrOLF_auth",
    reg: "__CON_TITU_reg",
  },
  maps: {
    apiKey: "GOOGLE_MAPS_KEy"
  }
};
