import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

export interface ISessionData {
  token: string;
  user: {
    id: number;
  };
}

export interface LoaderState {
  show: boolean;
  text: string;
}

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private broadcaster = new BehaviorSubject<LoaderState | null>(null);
  private currency = "INR";
  private onlineUsers: ISessionData[] = [];

  constructor(private router: Router) {}

  showLoader(text = ""): void {
    this.broadcaster.next({ show: true, text });
  }

  hideLoader(): void {
    this.broadcaster.next({ show: false, text: "" });
  }

  isLogin(): boolean {
    const userdata = this.getSession<ISessionData>(
      environment.session_keys.login,
      true,
    );
    if (userdata) {
    const cuser = this.getCookie<ISessionData>(
      environment.session_keys.login,
      true,
    );
    return !!(userdata && cuser && userdata.user.id > 0 && cuser.user.id > 0);
    }
    return false;
  }

  setSession<T>(key: string, value: T, encoded = false): void {
    const sessionValue = encoded
      ? btoa(encodeURIComponent(JSON.stringify(value)))
      : JSON.stringify(value);
    sessionStorage.setItem(key, sessionValue);
  }

  getSession<T>(key: string, encoded = false): T | null {
    try {
      let value = sessionStorage.getItem(key);
      if (value && encoded) {
        value = JSON.parse(decodeURIComponent(atob(value)));
      }
      return value as T;
    } catch (err) {
      console.error("Error retrieving session data:", err);
      return null;
    }
  }

  setCookie<T>(name: string, value: T, expiry = 1440, encrypted = false): void {
    const cookieValue = encrypted ? btoa(JSON.stringify(value)) : value;
    const expires = new Date(Date.now() + expiry * 60000).toUTCString();
    document.cookie = `${name}=${cookieValue};expires=${expires};path=/;SameSite=None;Secure`;
  }

  getCookie<T>(name: string, encrypted = false): T | null {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";").map((c) => c.trim());
    const cookie = ca.find((c) => c.startsWith(cookieName));
    if (!cookie) return null;

    const cookieValue = cookie.substring(cookieName.length);
    if (encrypted) {
      return JSON.parse(atob(cookieValue));
    }
    return cookieValue as unknown as T;
  }

  signOut(): void {
    sessionStorage.removeItem(environment.session_keys.login);
    this.router.navigate(["/"]).then(() => {
      window.location.reload();
    });
  }
}
